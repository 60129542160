import { Button, Col, Form, Input, message, Row, Spin, Upload } from "antd";
import PageHeader from "../../../components/HeaderPage";
import {useNavigate, useParams} from "react-router-dom";
import {AiOutlineCloudUpload} from "react-icons/ai";
import {useEffect, useState} from "react";
import {BASE_URL} from "../../../config/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CLIENT_API } from "../../../services/client.request";
import { submitEmployee } from "./request";
import { AxiosError } from "axios";
import { Notification } from "../../../components/Notifications/notification";

const UpdateEmployee = () => {
    const [form] = Form.useForm();
    const {user_id} = useParams();
    const navigate = useNavigate();
    const [files, setFiles] = useState<Array<any>>([]);

    const { data, isError, isFetching} = useQuery<any>({
        queryKey: ["users", user_id],
        queryFn: () => CLIENT_API.getOne({ url: `/users/${user_id}`}),
        enabled: !!user_id,
        refetchOnWindowFocus: false,
        retry: 0,
    })

    useEffect(() => {
        if (data?.status == 1) {
            let resData = data?.data

            form.setFieldsValue({
                ...resData
            })

            setFiles([{
                uid: '-1',
                name: "photo-00",
                url: `${BASE_URL}/file/${resData?.photo}`,
                status: "done"
            }])
        }
    }, [data?.status])

    useEffect(() => {
        if(isError) message.error("Malumot yuklashda xatolik!")
    }, [isError])

    const { mutate, isPending: submitLoading } = useMutation({
        mutationFn: (values) => submitEmployee(user_id, values),
        onSuccess: async (res) => {
          Notification("success", "update", res?.message);
          navigate("/employees")
        },
        onError: (error: AxiosError<any>) => {
          Notification("error", "create", error?.response?.data ? error?.response?.data?.message : "");
        },
        retry: 0,
    });

    return (
        <div>
            <Spin spinning={isFetching}>
                <PageHeader
                    breadCrumbData={[
                        {name: "Home", path: '/'},
                        {name: "Employees", path: '/employees'},
                        {name: "Employee update", path: '/employees'}
                    ]}
                    title={user_id ? "Update employee!":"Create employee!"}
                    isBack={true}
                />
                <div className="my-4">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={mutate}
                        autoComplete="off"
                    >
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                            <Form.Item
                                label="First name"
                                name="firstName"
                                rules={[{required: true, message: 'Please input firstName!'}]}
                            >
                                <Input placeholder="First name"/>
                            </Form.Item>
                            <Form.Item
                                label="Last name"
                                name="lastName"
                                rules={[{required: true, message: 'Please input lastName!'}]}
                            >
                                <Input placeholder="Last name"/>
                            </Form.Item>
                            <Form.Item
                                label="Job position"
                                name="jobPosition"
                                rules={[{required: true, message: 'Please input jobPosition!'}]}
                            >
                                <Input placeholder="Job position"/>
                            </Form.Item>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{required: true, message: 'Please input email!'}]}
                            >
                                <Input placeholder="Email"/>
                            </Form.Item>
                            <Form.Item
                                label="Phone number"
                                name="phone"
                                rules={[{required: true, message: 'Please input phone!'}]}
                            >
                                <Input placeholder="Phone"/>
                            </Form.Item>
                        </div>
                        <Row gutter={24}>
                            <Col span={3}>
                                <Form.Item
                                    label="Photo"
                                    name="photo"
                                    rules={[{required: true, message: 'Please select photo!'}]}
                                >
                                    <Upload onRemove={() => setFiles([])} fileList={files}
                                            customRequest={({action, file, onSuccess}: any) => {
                                                setFiles([{
                                                    name: file?.name,
                                                    url: URL.createObjectURL(file),
                                                    status: "done",
                                                    uid: file?.uid
                                                }])
                                                onSuccess("ok")
                                            }} onChange={({file, event}) => {
                                    }} listType="picture-card" accept="image/*">
                                        {files?.length === 0 && <AiOutlineCloudUpload size={35}/>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        
                        <Form.Item>
                            <Button loading={submitLoading} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </div>
    )
}
export default UpdateEmployee;