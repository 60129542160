import { Button, Collapse, Spin } from "antd";
import PageHeader from "../../components/HeaderPage";
import { useNavigate, useParams } from "react-router-dom";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import Actions from "../../components/Actions";

const TourItineraries = () => {

    const navigate = useNavigate();
    const {tour_id} = useParams();

    console.log("tour_id, id",tour_id);
    

    const { data, refetch, isPending } = useGetAllData({
        queryKey: ["tours/itineraries", tour_id],
        url: `tours/itineraries/${tour_id}`,
    })
    
    return (
        <Spin spinning={isPending}>
            <PageHeader
                breadCrumbData={[
                { name: "Home", path: '/' },
                { name: "Tours", path: '/tours' },
                { name: "Tour itineraries", path: '/price-conditions' }
                ]}
                title={"Tour itineraries"}
                isBack={true}
                btn={
                    <Button onClick={() => navigate(`/tours/itineraries/${tour_id}/update/create`)} type="primary">Create</Button>
                }
            />
            {
                data?.map((item: any) => (
                    <Collapse 
                        key={item?.id} 
                        className="my-3" 
                        expandIcon={() => {
                        return <Actions
                            id={Number(item?.id)}
                            url={'price-conditions'}
                            refetch={refetch}
                            onClickEdit={(e:any) => {e.stopPropagation(); navigate(`/tours/itineraries/${tour_id}/update/${item?.id}`)}}
                            onClickView={() => {}}
                        />}} 
                        expandIconPosition="right"
                    >
                        <Collapse.Panel header={item?.day} key="1">
                            <p dangerouslySetInnerHTML={{__html: item?.description}} />
                        </Collapse.Panel>
                    </Collapse>
                ))
            }
        </Spin>
    )
}
export default TourItineraries;