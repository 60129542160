import instance from "../../../config/axios.config";


export const submitPriceCondition = async (id: number | string | undefined, values: any) => {

    const formdata = new FormData();

    if(id) values.id = id;
    for (const key in values) {
        formdata.append(key, values[key]);
    }
    
    const response = await instance({ url: `/price-conditions`, method: "POST", data: formdata });

    return response.data;
}