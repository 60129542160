import { ThemeConfig } from "antd"


export const antdCustomTokens = ():ThemeConfig => {

    return {
        token: {
            colorPrimary: "#2F387F",
            colorText: "#002248",
            colorTextBase: "rgba(0, 0, 0, 1)",
            colorBgBase: "#fff",
        }
    }
}