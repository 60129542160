import instance from "../../../config/axios.config";

export const submitTransportType = async (id: number | string | undefined, values: any) => {

    const formdata = new FormData();        
        try {
            Object.entries(values).forEach(([k, v]: [string, any]) => {
                if (k === "mainPhoto") {
                    v?.fileList?.forEach((item: any) => {
                        formdata.append(k, item?.originFileObj);
                    })
                } else if (k === "photos") {
                    v?.forEach((item: any) => {
                        if(item?.originFileObj) formdata.append(k, item?.originFileObj);
                    })
                } else {
                    formdata.append(k, v)
                }
                
            });
            if (id) formdata.append("id", String(id));
        } catch (e) {
            throw Error()
        }

    const response = await instance({ url: '/transport-types', method: "POST", data: formdata });
    return response.data;
}