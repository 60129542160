import {Table} from "antd"
import type {ColumnsType} from 'antd/es/table';
import PageHeader from "../../components/HeaderPage";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import { ITour } from "../../models";

const Contacts = () => {
  
    const columns: ColumnsType<ITour> = [
        {
            title: '#',
            key: 'index',
            width: 60,
            render: (a, b, c) => c + 1,
        },
        {
            title: 'First name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
        },
    ];


    const {data, isPending} = useGetAllData<any>({
        queryKey: ["contacts"],
        url: "contacts",
    })


    return (
        <div>
            <PageHeader
                breadCrumbData={[
                    {name: "Home", path: '/'},
                    {name: "Contacts", path: '/contacts'}
                ]}
                title={"Contacts"}
            />
            <Table columns={columns} dataSource={data?.items?.length ? data?.items : []} loading={isPending} pagination={false}/>
        </div>
    )
}

export default Contacts;