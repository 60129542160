import {useState} from "react";
import { Button, Table } from "antd";
import type { ColumnsType } from 'antd/es/table';
import PageHeader from "../../components/HeaderPage";
import Actions from "../../components/Actions";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import UpdateData from "./crud/update";

const PriceCondition = () => {

  const [open, setOpen] = useState<boolean>(false);
  const [selectedItem, setselectedItem] = useState<any>();

    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Age from',
            dataIndex: 'ageFrom',
            key: 'age',
        },
        {
            title: 'Age to',
            dataIndex: 'ageTo',
            key: 'address',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => <Actions
                id={record?.id}
                url={'price-conditions'}
                refetch={refetch}
                onClickEdit={() => {setOpen(true); setselectedItem(record)}}
                onClickView={() => {setOpen(true); setselectedItem(record)}}
            />,
        },
    ];
      

    const { data, refetch, isPending } = useGetAllData({
        queryKey: ["price-conditions"],
        url: "price-conditions",
    })
      
    return(
        <div>
            <PageHeader
                breadCrumbData={[
                { name: "Home", path: '/' },
                { name: "Price condition", path: '/price-conditions' }
                ]}
                title={"Price condition"}
                btn={
                    <Button onClick={() => setOpen(true)} type="primary">Create</Button>
                }
            />
            <Table columns={columns} dataSource={data?.length ? data : []} loading={isPending} />

            <UpdateData open={open} setOpen={setOpen} refetch={refetch} selectedItem={selectedItem} setselectedItem={setselectedItem} />
        </div>
    )
}

export default PriceCondition;