import {Dispatch, useEffect} from 'react'
import { Button, Drawer, Form, Input, Select } from "antd";
import { useMutation } from '@tanstack/react-query';
import { Notification } from '../../../components/Notifications/notification';
import { AxiosError } from 'axios';
import { submitTourPrice } from './request';
import { useParams } from 'react-router-dom';
import useGetAllData from '../../../cutom-hooks/useGetAllData';

const UpdateTourPrice = ({open, setOpen, refetch, selectedItem, setselectedItem}: {open: boolean, setOpen: Dispatch<boolean>, refetch: any, selectedItem: any, setselectedItem: Dispatch<any>}) => {

    const [form] = Form.useForm();
    const {tour_id} = useParams();

    const onClose = () => {
        setOpen(false);
    };

    const { data,  isPending: selectLoading } = useGetAllData({
        queryKey: ["price-conditions"],
        url: "price-conditions",
    })

    useEffect(() => {
        form.setFieldsValue({
            priceConditionId: selectedItem?.priceCondition?.id,
            price: selectedItem?.price,
        })
    }, [selectedItem])

    const { mutate, isPending } = useMutation({
        mutationFn: (newVals) => submitTourPrice(selectedItem?.id, tour_id, newVals),
        onSuccess: async (res) => {
          Notification("success", "update", res?.message)
          refetch()
          setselectedItem(undefined)
          setOpen(false)
          form.resetFields()
        },
        onError: (error: AxiosError<any>) => {
          Notification("error", "update", error?.response?.data ? error?.response?.data?.message : "");
        },
        retry: 0,
    });

    return (
        <div>
            <Drawer key={1} title="Tour price update" placement="right" width={500} onClose={onClose} open={open}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={mutate}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Price condition"
                        name="priceConditionId"
                        rules={[{ required: true, message: 'Please select priceConditionId!' }]}
                    >
                        <Select
                            loading={selectLoading}
                            style={{ width: '100%' }}
                            options={data?.map((i: any) => ({value: i?.id, label: i?.name}))}
                            />
                    </Form.Item>
                    
                    <Form.Item
                        label="Tour price"
                        name="price"
                        rules={[{ required: true, message: 'Please input price!' }]}
                    >
                        <Input placeholder="Tour price age to" type='number' />
                    </Form.Item>
                    <Form.Item className='flex justify-end'>
                        <Button type="primary" htmlType="submit" loading={isPending}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    )
}
export default UpdateTourPrice;