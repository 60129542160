import instance from "../../../config/axios.config";


export const submitTourPrice = async (id: number | string | undefined, tour_id: string | undefined, values: any) => {

    const formdata = new FormData();

    if(id) values.id = id;
    for (const key in values) {
        formdata.append(key, values[key]);
    }
    formdata.append('tourId', String(tour_id));
    
    const response = await instance({ url: `/tours/price-tour`, method: "POST", data: formdata });

    return response.data;
}