
import { useEffect } from "react";
import RoutesMiddleware from "./routes/routes_middleware";
import { useAppDispatch } from "./store/service";
import { signIn } from "./store/auth";

function App() {

  const dispatch: any = useAppDispatch();

  useEffect(() => {
    dispatch(signIn({}));
  }, [])

  return (
    <RoutesMiddleware />
  );
}

export default App;
