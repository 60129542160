import { Button, Col, Form, Input, message, Row, Select, Spin, Upload } from "antd";
import PageHeader from "../../../components/HeaderPage";
import SunEditor from "suneditor-react";
import {editor_buttonList} from "../../../static_data/suneditor";
import {useNavigate, useParams} from "react-router-dom";
import {AiOutlineCloudUpload} from "react-icons/ai";
import {useEffect, useState} from "react";
import dayjs from 'dayjs'
import {BASE_URL} from "../../../config/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CLIENT_API } from "../../../services/client.request";
import { submitHotel } from "./request";
import { AxiosError } from "axios";
import { Notification } from "../../../components/Notifications/notification";

const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const UpdateHotel = () => {
    const [form] = Form.useForm();
    const {hotel_id} = useParams();
    const navigate = useNavigate();
    const [files, setFiles] = useState<Array<any>>([]);
    const [region_id, setregion_id] = useState<number>();
    const [textEditorContent, setTextEditorContent] = useState<string>("");

    const { data, isError, isFetching} = useQuery<any>({
        queryKey: ["hotels", hotel_id],
        queryFn: () => CLIENT_API.getOne({ url: `/hotels/${hotel_id}`}),
        enabled: !!hotel_id,
        refetchOnWindowFocus: false,
        retry: 0,
    })

    const { data: regions, isFetching: regionsFetching} = useQuery<any>({
        queryKey: ["regions"],
        queryFn: () => CLIENT_API.getOne({ url: `/regions`}),
        refetchOnWindowFocus: false,
        retry: 0,
    })
    
    useEffect(() => {
        if (data?.status == 1) {
            let resData = data?.data
            setFiles([{
                uid: '-1',
                name: "photo-00",
                url: `${BASE_URL}/file/${resData?.mainPhoto}`,
                status: "done"
            }])
            setTextEditorContent(resData?.description)
            setregion_id(resData?.district?.region?.id)
            form.setFieldsValue({
                ...resData,
                title: resData?.title,
                districtId: resData?.district?.id,
                photos: resData?.photos?.map((item: string, index: number) => ({
                    uid: index,
                    name: "photo-" + index,
                    url: `${BASE_URL}/file/${item}`,
                    status: "done"
                }))
            })
        }
    }, [data?.status])

    useEffect(() => {
        if(isError) message.error("Malumot yuklashda xatolik!")
    }, [isError])

    useEffect(() => {
        form.setFieldsValue({
            districtId: undefined
        })
    }, [region_id])

    const { mutate, isPending: submitLoading } = useMutation({
        mutationFn: (values) => submitHotel(hotel_id, values),
        onSuccess: async (res) => {
          Notification("success", "update", res?.message);
          navigate("/hotels")
        },
        onError: (error: AxiosError<any>) => {
          Notification("error", "create", error?.response?.data ? error?.response?.data?.message : "");
        },
        retry: 0,
    });

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <div>
            <Spin spinning={isFetching}>
                <PageHeader
                    breadCrumbData={[
                        {name: "Home", path: '/'},
                        {name: "Hotels", path: '/hotels'},
                        {name: "Hotel update", path: '/hotels'}
                    ]}
                    title={hotel_id ? "Update hotel!":"Create hotel!"}
                    isBack={true}
                />
                <div className="my-4">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={mutate}
                        autoComplete="off"
                    >
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[{required: true, message: 'Please input Title!'}]}
                            >
                                <Input placeholder="Hotel name"/>
                            </Form.Item>
                            <Form.Item
                                label="Region"
                                rules={[{required: true, message: 'Please select region!'}]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a region"
                                    optionFilterProp="children"
                                    onChange={(e) => setregion_id(e)}
                                    value={region_id}
                                    filterOption={filterOption}
                                    loading={regionsFetching}
                                    options={regions?.data?.map((item: any) => ({label: item?.name, value: item?.id}))}
                                />
                            </Form.Item>
                            <Form.Item
                                label="District"
                                name="districtId"
                                rules={[{required: true, message: 'Please select district!'}]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a district"
                                    optionFilterProp="children"
                                    disabled={!region_id}
                                    filterOption={filterOption}
                                    options={regions?.data?.find((region: any) => region?.id === region_id)?.districts?.map((item: any) => ({label: item?.name, value: item?.id}))}
                                />
                            </Form.Item>
                        </div>
                        <Row gutter={24}>
                            <Col span={3}>
                                <Form.Item
                                    label="Main photo"
                                    name="mainPhoto"
                                    rules={[{required: true, message: 'Please select main photo!'}]}
                                >
                                    <Upload onRemove={() => setFiles([])} fileList={files}
                                            customRequest={({action, file, onSuccess}: any) => {
                                                setFiles([{
                                                    name: file?.name,
                                                    url: URL.createObjectURL(file),
                                                    status: "done",
                                                    uid: file?.uid
                                                }])
                                                onSuccess("ok")
                                            }} onChange={({file, event}) => {
                                    }} listType="picture-card" accept="image/*">
                                        {files?.length === 0 && <AiOutlineCloudUpload size={35}/>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={21}>
                                <Form.Item
                                    valuePropName='fileList'
                                    label="Extra photos"
                                    name="photos"
                                    getValueFromEvent={normFile}
                                    rules={[{required: true, message: 'Please select photos!'}]}
                                >
                                    <Upload
                                        customRequest={({onSuccess}: any) => {
                                            onSuccess("ok")
                                        }} listType="picture-card" accept="image/*">
                                        <AiOutlineCloudUpload size={35}/>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            label={"Description"}
                            name={`description`}
                            shouldUpdate
                            rules={[{required: true, message: `Please input description`}]}
                        >
                            <SunEditor
                                setContents={textEditorContent}
                                height='240px'
                                autoFocus={false}
                                placeholder={"Enter description" ?? ""}
                                setOptions={{
                                    fontSize: [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
                                    fontSizeUnit: "px",
                                    buttonList: editor_buttonList
                                }}/>
                        </Form.Item>
                        <Form.Item>
                            <Button loading={submitLoading} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </div>
    )
}
export default UpdateHotel;