import {AxiosRequestConfig} from "axios";
import {PayloadAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import instance from "../../config/axios.config";

export const signIn = createAsyncThunk(
    'signIn',
    async (params: { username?: string, password?: string }, {rejectWithValue}) => {

        try {
            const {username = '', password = ''} = params;

            const formdata = new FormData();

            formdata.append("username", username);
            formdata.append("password", password);

            const isHasToken = localStorage.getItem("access_token_aest_tour");

            const options: AxiosRequestConfig = isHasToken ?
                {url: `/auth/me`, method: 'GET'} :
                {url: `/auth/login`, method: 'POST', data: formdata}

            if (isHasToken || (username && password)) {
                const response = await instance(options);

                if (response?.status !== 200) return rejectWithValue(new Error("Authorization error!"))

                if (!isHasToken) {
                    localStorage.setItem('access_token_aest_tour', response.data?.data?.token);
                }

                return {};
            }else {
                return rejectWithValue(new Error("Authorization error!"))
            }

        } catch (error: any) {

            return rejectWithValue(error?.response);

        }
    }
)


export interface IAuth {
    isLoading: boolean,
    status: 'pending' | 'success' | 'error',
    isAuthenticated: boolean
}

export const initialStateAuth: IAuth = {
    isLoading: false,
    status: 'success',
    isAuthenticated: false
}


const authSlice = createSlice({
    name: 'auth',
    initialState: initialStateAuth,
    reducers: {
        signOut(state) {
            state.isAuthenticated = false;
            state.status = 'success';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(signIn.pending, (state) => {
            state.isLoading = true;
            state.status = 'pending';
        });
        builder.addCase(signIn?.fulfilled.type, (state, action) => {

            state.isAuthenticated = true;
            state.isLoading = false;

        });
        builder.addCase(signIn?.rejected.type, (state, action) => {
            state = {
                ...state,
                isLoading: false,
                status: 'error'
            }
        })
    }
})


export const AUTH_ACTIONS = authSlice.actions;

export default authSlice;