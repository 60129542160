import instance from "../../../config/axios.config";

export const submitData = async (id: number | string | undefined, tour_id: number | string | undefined, values: any) => {

    const formdata = new FormData();

        try {
            Object.entries(values).forEach(([k, v]: [string, any]) => {
                formdata.append(k, v)
            });
            if (id && (id !== "create")) formdata.append("id", String(id));
        } catch (e) {
            throw Error()
        }

    const response = await instance({ url: `/tours/itineraries/${tour_id}`, method: "POST", data: formdata });
    return response.data;
}