import {Button, Form, Input, message, Spin} from "antd";
import PageHeader from "../../../components/HeaderPage";
import SunEditor from "suneditor-react";
import {editor_buttonList} from "../../../static_data/suneditor";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CLIENT_API } from "../../../services/client.request";
import { submitData } from "./request";
import { AxiosError } from "axios";
import { Notification } from "../../../components/Notifications/notification";

const UpdateTourItinerary = () => {

    const [form] = Form.useForm();
    const {tour_id, id} = useParams();
    const navigate = useNavigate();
    const [textEditorContent, setTextEditorContent] = useState<string>("");

    const { data, isError, isFetching} = useQuery<any>({
        queryKey: ["tours/itineraries", tour_id, id],
        queryFn: () => CLIENT_API.getAll({ url: `/tours/itineraries/${tour_id}`}),
        enabled: !!id,
        refetchOnWindowFocus: false,
        retry: 0,
    })
    
    useEffect(() => {
        if (data?.length) {
            let resData = data?.find((i: any) => i?.id == id)
            setTextEditorContent(resData?.description)
            form.setFieldsValue({
                ...resData,
            })
        }
    }, [data?.length])

    useEffect(() => {
        if(isError) message.error("Malumot yuklashda xatolik!")
    }, [isError])

    const { mutate, isPending: submitLoading } = useMutation({
        mutationFn: (values) => submitData(id, tour_id, values),
        onSuccess: async (res) => {
          Notification("success", "update", res?.message);
          navigate(`/tours/itineraries/${tour_id}`)
        },
        onError: (error: AxiosError<any>) => {
          Notification("error", "create", error?.response?.data ? error?.response?.data?.message : "");
        },
        retry: 0,
    });

    return (
        <div>
            <Spin spinning={isFetching}>
                <PageHeader
                    breadCrumbData={[
                        {name: "Home", path: '/'},
                        {name: "Tours", path: '/tours'},
                        {name: "Tour itinerareis", path: `/tours/itineraries/${tour_id}`},
                        {name: "Tour marshrut update", path: '/tours'}
                    ]}
                    title={tour_id?"Update tour marshrut!":"Create tour marshrut!"}
                    isBack={true}
                />
                <div className="my-4">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={mutate}
                        autoComplete="off"
                    >
                        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4">
                            <Form.Item
                                label="Day"
                                name="day"
                                rules={[{required: true, message: 'Please input Day!'}]}
                            >
                                <Input placeholder="Tour day"/>
                            </Form.Item>

                        </div>
                        
                        <Form.Item
                            label={"Description"}
                            name={`description`}
                            shouldUpdate
                            rules={[{required: true, message: `Please input description`}]}
                        >
                            <SunEditor
                                setContents={textEditorContent}
                                height='240px'
                                autoFocus={false}
                                placeholder={"Enter description" ?? ""}
                                setOptions={{
                                    fontSize: [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
                                    fontSizeUnit: "px",
                                    buttonList: editor_buttonList
                                }}/>
                        </Form.Item>
                        <Form.Item>
                            <Button loading={submitLoading} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </div>
    )
}
export default UpdateTourItinerary;