import {Button, Table} from "antd"
import type {ColumnsType} from 'antd/es/table';
import PageHeader from "../../components/HeaderPage";
import Actions from "../../components/Actions";
import {useNavigate} from "react-router-dom";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import {ITour} from "../../models";
import {BASE_URL} from "../../config/utils";

const Gallery = () => {
    
    const navigate = useNavigate()

    const columns: ColumnsType<ITour> = [
        {
            title: '#',
            key: 'index',
            width: 60,
            render: (a, b, c) => c + 1,
        },
        {
            title: 'Photo',
            dataIndex: 'photo',
            key: 'photo',
            width: 200,
            render: (value) => <img src={`${BASE_URL}/file/${value}`} width={150}/>
        },
        {
            title: 'Title',
            dataIndex: 'description',
            key: 'title',
        },
        {
            title: 'Actions',
            key: 'id',
            dataIndex: "id",
            width:90,
            render: (_, item) => <Actions
                id={_}
                url={'galleries'}
                refetch={refetch}
                onClickEdit={() => navigate(`/gallery/update/${item?.id}`)}
                onClickView={() => navigate(`/gallery/update/${item.id}`)}
            />
        }
    ];


    const {data, refetch, isPending} = useGetAllData<any>({
        queryKey: ["galleries"],
        url: "galleries",
    })


    return (
        <div>
            <PageHeader
                breadCrumbData={[
                    {name: "Home", path: '/'},
                    {name: "Galleries", path: '/gallery'}
                ]}
                title={"Galleries"}
                btn={
                    <Button onClick={() => navigate(`/gallery/create`)} type="primary">Create</Button>
                }
            />
            <Table columns={columns} dataSource={data?.items?.length ? data?.items : []} loading={isPending}/>
        </div>
    )
}

export default Gallery;