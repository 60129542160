import {FaCarSide, FaUmbrellaBeach, FaUsers} from "react-icons/fa"
import Dashboard from "../pages/dashboard"
import Hotels from "../pages/hotels"
import Login from "../pages/login"
import Tours from "../pages/tours"
import {RoutesTypeElement} from "./types"
import {HiOutlineHome} from 'react-icons/hi'
import {BiSolidCity, BiSolidHotel} from "react-icons/bi"
import {ImPriceTags} from "react-icons/im"
import UpdateTour from "../pages/tours/crud/update"
import PriceCondition from "../pages/price-conditions"
import TourItineraries from "../pages/tour-itineraries"
import UpdateTourItinerary from "../pages/tour-itineraries/crud/update"
import TourPrices from "../pages/tour-prices"
import UpdateHotel from "../pages/hotels/crud/update"
// @ts-ignore
import Cities from "../pages/cities"
import UpdateCity from "../pages/cities/crud/update"
import Employees from "../pages/employees"
import UpdateEmployee from "../pages/employees/crud/update"
import Mices from "../pages/mice"
import UpdateMice from "../pages/mice/crud/update"
import { LuGalleryHorizontalEnd } from "react-icons/lu";
import Transports from "../pages/transports"
import UpdateTransport from "../pages/transports/crud/update"
import TransportTypes from "../pages/transport-types"
import Bookings from "../pages/bookings"
import { TbBrandBooking } from "react-icons/tb";
import Contacts from "../pages/contacts"
import { TiMessages } from "react-icons/ti";
import Gallery from "../pages/gallery"
import { RiGalleryFill } from "react-icons/ri";
import UpdateGallery from "../pages/gallery/crud/update"

export const public_routes: RoutesTypeElement[] = [
    {
        name: "Login",
        path: "/signin",
        component: Login,
        config: {
            key: "unlock",
            icon: HiOutlineHome,
            structure: "nonlayout",
            exact: true,
            isShowLink: false,
        },
        submenu: [],
    },

]

export const protected_routes: RoutesTypeElement[] = [
    {
        name: "Dashbord",
        path: "/",
        component: Dashboard,
        config: {
            key: "unlock",
            icon: HiOutlineHome,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },

    // tours 
    {
        name: "Tours",
        path: "/tours",
        component: Tours,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: []
    },
    {
        name: "Tours update",
        path: "/tours/create",
        component: UpdateTour,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },
    {
        name: "Tours update",
        path: "/tours/update/:tour_id",
        component: UpdateTour,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },
    {
        name: "Tours update",
        path: "/tours/create/:tour_id",
        component: UpdateTour,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },
    {
        name: "Tours update",
        path: "/prices-by-tour/:tour_id",
        component: UpdateTour,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },
    {
        name: "Tours update",
        path: "/tours/itineraries/:tour_id",
        component: TourItineraries,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },
    {
        name: "Tours itineraries",
        path: "/tours/itineraries/:tour_id/update/:id",
        component: UpdateTourItinerary,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },
    {
        name: "Tours itineraries",
        path: "/tours/prices/:tour_id",
        component: TourPrices,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },
    {
        name: "Tours update",
        path: "/tours/update/:tour_id",
        component: UpdateTour,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: [],
    },
    {
        name: "Price condition",
        path: "/price-conditions",
        component: PriceCondition,
        config: {
            key: "unlock",
            icon: ImPriceTags,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },

    // cities 
    {
        name: "Cities",
        path: "/cities",
        component: Cities,
        config: {
            key: "unlock",
            icon: BiSolidCity,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },

    {
        name: "city create",
        path: "/cities/create",
        component: UpdateCity,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },
    {
        name: "City update",
        path: "/cities/update/:city_id",
        component: UpdateCity,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },


    // hotels 
    {
        name: "Hotels",
        path: "/hotels",
        component: Hotels,
        config: {
            key: "unlock",
            icon: BiSolidHotel,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },
    {
        name: "Hotel update",
        path: "/hotels/create",
        component: UpdateHotel,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },
    {
        name: "Hotel update",
        path: "/hotels/update/:hotel_id",
        component: UpdateHotel,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },

    // employees 
    {
        name: "Employees",
        path: "/employees",
        component: Employees,
        config: {
            key: "unlock",
            icon: FaUsers,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },
    {
        name: "Employee update",
        path: "/employees/create",
        component: UpdateEmployee,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },
    {
        name: "Employee update",
        path: "/employees/update/:user_id",
        component: UpdateEmployee,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },

    // Mices 
    {
        name: "Mices",
        path: "/mices",
        component: Mices,
        config: {
            key: "unlock",
            icon: LuGalleryHorizontalEnd,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },
    {
        name: "Mice update",
        path: "/mices/create",
        component: UpdateMice,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },
    {
        name: "Mice update",
        path: "/mices/update/:mice_id",
        component: UpdateMice,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },

    // Gallery 
    {
        name: "Gallery",
        path: "/gallery",
        component: Gallery,
        config: {
            key: "unlock",
            icon: RiGalleryFill,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },
    {
        name: "Mice update",
        path: "/gallery/create",
        component: UpdateGallery,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },
    {
        name: "Mice update",
        path: "/gallery/update/:gallery_id",
        component: UpdateGallery,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },
    

    // Transports 
    {
        name: "Transports",
        path: "/transports",
        component: Transports,
        config: {
            key: "unlock",
            icon: FaCarSide,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },
    {
        name: "Transport update",
        path: "/transports/create",
        component: UpdateTransport,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },
    {
        name: "Transport update",
        path: "/transports/update/:transport_id",
        component: UpdateTransport,
        config: {
            key: "unlock",
            icon: FaUmbrellaBeach,
            structure: "layout",
            exact: true,
            isShowLink: false,
        },
        submenu: []
    },

    // Transport types 
    {
        name: "Transport types",
        path: "/transport-types",
        component: TransportTypes,
        config: {
            key: "unlock",
            icon: FaCarSide,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },

    // bookings 
    {
        name: "Bookings",
        path: "/bookings",
        component: Bookings,
        config: {
            key: "unlock",
            icon: TbBrandBooking,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },

     // Contacts 
     {
        name: "Contacts",
        path: "/contacts",
        component: Contacts,
        config: {
            key: "unlock",
            icon: TiMessages,
            structure: "layout",
            exact: true,
            isShowLink: true,
        },
        submenu: [],
    },
]