import {useState} from 'react'
import { Button, Table } from "antd";
import type { ColumnsType } from 'antd/es/table';
import PageHeader from "../../components/HeaderPage";
import Actions from "../../components/Actions";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import { useParams } from 'react-router-dom';
import UpdateTourPrice from './crud/update';

const TourPrices = () => {

    const {tour_id} = useParams();

    const [open, setOpen] = useState<boolean>(false);
    const [selectedItem, setselectedItem] = useState<any>();
  
      const columns: ColumnsType<any> = [
          {
              title: 'Name',
              key: 'name',
              render: (e) => e?.priceCondition?.name
          },
          {
              title: 'Age from',
              key: 'agefrom',
              render: (e) => e?.priceCondition?.ageFrom
          },
          {
              title: 'Age to',
              key: 'ageto',
              render: (e) => e?.priceCondition?.ageTo
          },
          {
            title: 'Age to',
            key: 'price',
            render: (e) => `$ ${e?.price}`
        },
          {
              title: 'Action',
              key: 'action',
              render: (_, record) => <Actions
                  id={record?.id}
                  url={'tours/price-tour'}
                  refetch={refetch}
                  onClickEdit={() => {setOpen(true); setselectedItem(record)}}
                  onClickView={() => {setOpen(true); setselectedItem(record)}}
              />,
          },
      ];
        
  
      const { data, refetch, isPending } = useGetAllData({
          queryKey: ["tours/price-tour", tour_id],
          url: `tours/price-tour/${tour_id}`,
      })
        
      return(
          <div>
              <PageHeader
                  breadCrumbData={[
                  { name: "Home", path: '/' },
                  { name: "Tours", path: '/tours' },
                  { name: "Tour prices", path: '/price-conditions' }
                  ]}
                  title={"Tour prices"}
                  isBack={true}
                  btn={
                      <Button onClick={() => setOpen(true)} type="primary">Create</Button>
                  }
              />
              <Table columns={columns} dataSource={data?.length ? data : []} loading={isPending} />
  
              <UpdateTourPrice open={open} setOpen={setOpen} refetch={refetch} selectedItem={selectedItem} setselectedItem={setselectedItem} />
          </div>
      )
}
export default TourPrices;