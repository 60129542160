import {Dispatch, useEffect} from 'react'
import { Button, Drawer, Form, Input } from "antd";
import { useMutation } from '@tanstack/react-query';
import { Notification } from '../../../components/Notifications/notification';
import { AxiosError } from 'axios';
import { submitTransportType } from './request';

const UpdateData = ({open, setOpen, refetch, selectedItem, setselectedItem}: {open: boolean, setOpen: Dispatch<boolean>, refetch: any, selectedItem: any, setselectedItem: Dispatch<any>}) => {

    const [form] = Form.useForm();

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        form.setFieldsValue({
            name: selectedItem?.name,
            ageFrom: selectedItem?.ageFrom,
            ageTo: selectedItem?.ageTo,
        })
    }, [selectedItem])

    const { mutate, isPending } = useMutation({
        mutationFn: (newVals) => submitTransportType(selectedItem?.id, newVals),
        onSuccess: async (res) => {
          Notification("success", "update", res?.message)
          refetch()
          setselectedItem(undefined)
          setOpen(false)
        },
        onError: (error: AxiosError<any>) => {
          Notification("error", "update", error?.response?.data ? error?.response?.data?.message : "");
        },
        retry: 0,
    });

    return (
        <div>
            <Drawer key={3} title="Transport type update" placement="right" width={500} onClose={onClose} open={open}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={mutate}
                    autoComplete="off"
                >
                        <Form.Item
                            label="Title"
                            name="name"
                            rules={[{ required: true, message: 'Please input title!' }]}
                        >
                                <Input placeholder="Transport type title" />
                        </Form.Item>

                        <Form.Item className='flex justify-end'>
                            <Button type="primary" htmlType="submit" loading={isPending}>
                                Save
                            </Button>
                        </Form.Item>
                </Form>
            </Drawer>
        </div>
    )
}
export default UpdateData;