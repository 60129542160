import { Button, Col, Form, Input, message, Row, Select, Spin, Upload } from "antd";
import PageHeader from "../../../components/HeaderPage";
import {useNavigate, useParams} from "react-router-dom";
import {AiOutlineCloudUpload} from "react-icons/ai";
import {useEffect, useState} from "react";
import {BASE_URL} from "../../../config/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CLIENT_API } from "../../../services/client.request";
import { submitTransport } from "./request";
import { AxiosError } from "axios";
import { Notification } from "../../../components/Notifications/notification";
import useGetAllData from "../../../cutom-hooks/useGetAllData";

const UpdateTransport = () => {
    const [form] = Form.useForm();
    const {transport_id} = useParams();
    const navigate = useNavigate();
    const [files, setFiles] = useState<Array<any>>([]);

    const { data, isError, isFetching} = useQuery<any>({
        queryKey: ["transports", transport_id],
        queryFn: () => CLIENT_API.getOne({ url: `/transports/${transport_id}`}),
        enabled: !!transport_id,
        refetchOnWindowFocus: false,
        retry: 0,
    })

    const {data: transportTypes} = useGetAllData<any>({
        queryKey: ["transport-types"],
        url: "transport-types",
    })

    useEffect(() => {
        if (data?.status == 1) {
            let resData = data?.data
            setFiles([{
                uid: '-1',
                name: "photo-00",
                url: `${BASE_URL}/file/${resData?.mainPhoto}`,
                status: "done"
            }])
            form.setFieldsValue({
                ...resData,
                transportTypeId: resData?.transportType?.id,
                photos: resData?.photos?.map((item: string, index: number) => ({
                    uid: index,
                    name: "photo-" + index,
                    url: `${BASE_URL}/file/${item}`,
                    status: "done"
                }))
            })
        }
    }, [data?.status])

    useEffect(() => {
        if(isError) message.error("Malumot yuklashda xatolik!")
    }, [isError])

    const { mutate, isPending: submitLoading } = useMutation({
        mutationFn: (values) => submitTransport(transport_id, values),
        onSuccess: async (res) => {
          Notification("success", "update", res?.message);
          navigate("/transports")
        },
        onError: (error: AxiosError<any>) => {
          Notification("error", "create", error?.response?.data ? error?.response?.data?.message : "");
        },
        retry: 0,
    });

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };    

    return (
        <div>
            <Spin spinning={isFetching}>
                <PageHeader
                    breadCrumbData={[
                        {name: "Home", path: '/'},
                        {name: "Transports", path: '/transports'},
                        {name: "Transport update", path: '/transports'}
                    ]}
                    title={transport_id ? "Update transport!":"Create transport!"}
                    isBack={true}
                />
                <div className="my-4">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={mutate}
                        autoComplete="off"
                    >
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[{required: true, message: 'Please input Title!'}]}
                            >
                                <Input placeholder="Hotel name"/>
                            </Form.Item>
                            <Form.Item
                                label="Brand"
                                name="brand"
                                rules={[{required: true, message: 'Please input Title!'}]}
                            >
                                <Input placeholder="Hotel name"/>
                            </Form.Item>
                            <Form.Item
                                label="Capacity"
                                name="capacity"
                                rules={[{required: true, message: 'Please input capacity!'}]}
                            >
                                <Input placeholder="Capacity"/>
                            </Form.Item>
                            <Form.Item
                                label="Trasnport type"
                                name="transportTypeId"
                                rules={[{required: true, message: 'Please select Trasnport type!'}]}
                            >
                                <Select
                                    placeholder="Select a transport type"
                                    optionFilterProp="children"
                                    options={transportTypes?.map((item: any) => ({label: item?.name, value: item?.id}))}
                                />
                            </Form.Item>
                        </div>
                        <Row gutter={24}>
                            <Col span={3}>
                                <Form.Item
                                    label="Main photo"
                                    name="mainPhoto"
                                    rules={[{required: true, message: 'Please select main photo!'}]}
                                >
                                    <Upload onRemove={() => setFiles([])} fileList={files}
                                            customRequest={({action, file, onSuccess}: any) => {
                                                setFiles([{
                                                    name: file?.name,
                                                    url: URL.createObjectURL(file),
                                                    status: "done",
                                                    uid: file?.uid
                                                }])
                                                onSuccess("ok")
                                            }} onChange={({file, event}) => {
                                    }} listType="picture-card" accept="image/*">
                                        {files?.length === 0 && <AiOutlineCloudUpload size={35}/>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={21}>
                                <Form.Item
                                    valuePropName='fileList'
                                    label="Extra photos"
                                    name="photos"
                                    getValueFromEvent={normFile}
                                    rules={[{required: true, message: 'Please select photos!'}]}
                                >
                                    <Upload
                                        customRequest={({onSuccess}: any) => {
                                            onSuccess("ok")
                                        }} listType="picture-card" accept="image/*">
                                        <AiOutlineCloudUpload size={35}/>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            label={"Description"}
                            name={`description`}
                            shouldUpdate
                            rules={[{required: true, message: `Please input description`}]}
                        >
                            <Input.TextArea rows={3} />
                        </Form.Item>
                        <Form.Item>
                            <Button loading={submitLoading} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </div>
    )
}
export default UpdateTransport;