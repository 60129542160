import {Button, Table} from "antd"
import type {ColumnsType} from 'antd/es/table';
import PageHeader from "../../components/HeaderPage";
import Actions from "../../components/Actions";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import {ITour} from "../../models";
import {useState} from 'react'
// @ts-ignore  
import UpdateData from "./crud/update";

const TransportTypes = () => {
    
    const [open, setOpen] = useState<boolean>(false);
    const [selectedItem, setselectedItem] = useState<any>();
  
    const columns: ColumnsType<ITour> = [
        {
            title: '#',
            key: 'index',
            width: 60,
            render: (a, b, c) => c + 1,
        },
        {
            title: 'Title',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Actions',
            key: 'id',
            dataIndex: "id",
            width:90,
            render: (_, item) => <Actions
                id={_}
                url={'transport-types'}
                refetch={refetch}
                onClickEdit={() => {setOpen(true); setselectedItem(item)}}
                onClickView={() => {setOpen(true); setselectedItem(item)}}
            />
        }
    ];


    const {data, refetch, isPending} = useGetAllData<any>({
        queryKey: ["transport-types"],
        url: "transport-types",
    })


    return (
        <div>
            <PageHeader
                breadCrumbData={[
                    {name: "Home", path: '/'},
                    {name: "Transport types", path: '/transport-types'}
                ]}
                title={"Transport types"}
                btn={
                    <Button onClick={() => setOpen(true)} type="primary">Create</Button>
                }
            />
            <Table columns={columns} dataSource={data?.length ? data : []} loading={isPending} pagination={false}/>

            <UpdateData open={open} setOpen={setOpen} refetch={refetch} selectedItem={selectedItem} setselectedItem={setselectedItem} />

        </div>
    )
}

export default TransportTypes;