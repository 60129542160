import {Button, Table} from "antd"
import type {ColumnsType} from 'antd/es/table';
import PageHeader from "../../components/HeaderPage";
import Actions from "../../components/Actions";
import {Link, useNavigate} from "react-router-dom";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import {ITour} from "../../models";
import {BASE_URL} from "../../config/utils";
import {AiOutlineArrowRight} from "react-icons/ai";

const Tours = () => {
    
    const navigate = useNavigate()

    const columns: ColumnsType<ITour> = [
        {
            title: '#',
            key: 'index',
            render: (a, b, c) => c + 1,
        },
        {
            title: 'Main photo',
            dataIndex: 'mainPhoto',
            key: 'mainPhoto',
            render: (value) => <img src={`${BASE_URL}/file/${value}`} width={150}/>
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
            key: 'slug',
        },
        {
            title: 'Creator',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: 'Start date',
            key: 'startDate',
            dataIndex: 'startDate',
            render:(val)=>new Date(val).toLocaleDateString()
        },
        {
            title: 'End date',
            key: 'endDate',
            dataIndex: 'endDate',
            render:(val)=>new Date(val).toLocaleDateString()
        },
        {
            title: 'Itineraries',
            key: 'id',
            dataIndex: "id",
            render: (value) =><Link to={'/tours/itineraries/'+value}><span className="align-middle">Itineraries <AiOutlineArrowRight className="ms-1"/></span></Link>,
        },
        {
            title: 'Prices',
            key: 'id',
            dataIndex: "id",
            render: (value) =><Link to={'/tours/prices/'+value}><span className="align-middle">Prices <AiOutlineArrowRight className="ms-1"/></span></Link>,
        },
        {
            title: 'View',
            key: 'view',
            dataIndex: "view"
        },
        {
            title: 'Actions',
            key: 'id',
            dataIndex: "id",
            width:90,
            render: (_, item) => <Actions
                id={_}
                url={'tours'}
                refetch={refetch}
                onClickEdit={() => navigate(`/tours/update/${item?.id}`)}
                onClickView={() => navigate(`/tours/update/${item.id}`)}
            />
        }
    ];


    const {data, refetch, isPending} = useGetAllData<any>({
        queryKey: ["tours"],
        url: "tours",
    })


    return (
        <div>
            <PageHeader
                breadCrumbData={[
                    {name: "Home", path: '/'},
                    {name: "Tours", path: '/tours'}
                ]}
                title={"Tours"}
                btn={
                    <Button onClick={() => navigate(`/tours/create`)} type="primary">Create</Button>
                }
            />
            <Table columns={columns} dataSource={data?.items?.length ? data?.items : []} loading={isPending}/>
        </div>
    )
}

export default Tours;