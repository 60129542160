import {Table, Tag} from "antd"
import type {ColumnsType} from 'antd/es/table';
import PageHeader from "../../components/HeaderPage";
import useGetAllData from "../../cutom-hooks/useGetAllData";
import {ITour} from "../../models";
import { Link } from "react-router-dom";

const Bookings = () => {
  
    const columns: ColumnsType<ITour> = [
        {
            title: '#',
            key: 'index',
            width: 60,
            render: (a, b, c) => c + 1,
        },
        {
            title: 'First name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Tour',
            dataIndex: 'tour',
            key: 'tour',
            render(value) {
                return <Link to={`/tours/update/${value?.id}`}>{value?.title}</Link>
            },
        },
        {
            title: 'Payment',
            dataIndex: 'payed',
            key: 'payed',
            render(value) {
                return <Tag color={value ? "success" : "warning"}>{value ? "Paid" : "Payment failed"}</Tag>
            },
        },
        {
            title: 'Price',
            dataIndex: 'prices',
            key: 'prices',
            render(value) {
                return <div>
                    {value?.map((price: any) => (
                        <p className="my-2"> {price?.count} {price?.priceTour?.priceCondition?.name}, ${price?.priceTour?.price * price?.count}</p>
                    ))}
                </div>
            },
        },
    ];


    const {data, isPending} = useGetAllData<any>({
        queryKey: ["booking"],
        url: "booking",
    })


    return (
        <div>
            <PageHeader
                breadCrumbData={[
                    {name: "Home", path: '/'},
                    {name: "Bookings", path: '/bookings'}
                ]}
                title={"Bookings"}
            />
            <Table columns={columns} dataSource={data?.items?.length ? data?.items : []} loading={isPending} pagination={false}/>
        </div>
    )
}

export default Bookings;