import instance from "../../../config/axios.config";
import dayjs from 'dayjs'

export const submitTour = async (id: number | string | undefined, values: any) => {

    const formdata = new FormData();
        console.log("values", values);
        
        try {
            Object.entries(values).forEach(([k, v]: [string, any]) => {
                if (k === "mainPhoto") {
                    v?.fileList?.forEach((item: any) => {
                        formdata.append(k, item?.originFileObj);
                    })
                } else if (k === "photos") {
                    v?.forEach((item: any) => {
                        if(item?.originFileObj) formdata.append(k, item?.originFileObj);
                    })
                } else if (k === "createdDate") {
                    let date: any = dayjs(v).format("DD-MM-YYYY");
                    console.log(date)
                    formdata.append(k, date);
                }else if(k == "date"){
                    if (dayjs(values[k][0])) formdata.append("startDate", dayjs(values[k][0]).format("DD-MM-YYYY"));
                    if (dayjs(values[k][1])) formdata.append("endDate", dayjs(values[k][1]).format("DD-MM-YYYY"));
                } else {
                    formdata.append(k, v)
                }
                
            });
            if (id) formdata.append("id", String(id));
        } catch (e) {
            throw Error()
        }

    const response = await instance({ url: '/tours', method: "POST", data: formdata });
    return response.data;
}