import { Button, Modal, Tooltip } from "antd";
import { ReactNode, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { delete_data } from "../../services";

type TypeDeleteData = {
  children: ReactNode,
  id: number | string,
  url: string,
  refetch: any,
  className?: string,
  placement?: "left" | "right" | "top" | "bottom",
  navigateUrl?: string,
  data?: any
}

const DeleteData: React.FC<TypeDeleteData> = ({ children, className, url, id, refetch, placement, navigateUrl, data }) => {
  const navigate = useNavigate()
  const [visible, setVisible] = useState<boolean>(false);

  const { mutate, isPending } = useMutation({
    mutationFn: () => delete_data(url, id, data),
    onSuccess: () => {
      if (navigateUrl) {
        navigate(navigateUrl)
      } else {
        refetch();
      }
      setVisible(false);
    }
  });

  return (
    <>
      <Tooltip placement={placement ?? "topLeft"} title={"Delete"}>
        <span onClick={() => setVisible(true)} className={className ?? "flex-center"}>
          {children}
        </span>
      </Tooltip>
      <Modal
        open={visible}
        footer={null}
        title={null}
        closable={false}
        centered
        width={416}
      >
        <div className="flex">
          <div className="mr-[20px]" ><MdDelete color="#FF4D4F" fontSize={24} display={"inline-block"} /></div>
          <div>
            <h5 className="text-[16px] font-medium" >{"Do you want to delete information?"}</h5>
            <span className="text-[14px] font-light opacity-75" >{"Once the data is deleted, it cannot be recovered."}</span>
          </div>
        </div>
        <div className="flex flex-end justify-end mt-[24px]">
          <Button className="me-2" onClick={() => setVisible(false)} >{"No"}</Button>
          <Button type="primary" danger loading={isPending} onClick={() => mutate()} >{"Yes"}</Button>
        </div>
      </Modal>
    </>
  )
}

export default DeleteData;